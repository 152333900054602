import StoryblokClient from 'storyblok-js-client'

export default defineNuxtRouteMiddleware(async (to) => {
  const removeLang = (word: string) => word.replace('/de/', '/')
  const removeHash = (url: string) => url.split('#')[0]

  const cleanPath = removeLang(removeHash(to.path))

  const client = new StoryblokClient({
    accessToken: useRuntimeConfig().public.storyblokAccessToken,
  })
  const { data } = await client.getStory(cleanPath, {
    version: useRuntimeConfig().public.storyblokApiVersion as
      | 'draft'
      | 'published',
  })
  const { available_languages: availableLanguages } = data.story.content

  const localeFromMiddlewareRouteName = (to.name as string)?.split('___')[1]

  if (
    availableLanguages &&
    availableLanguages.length < 2 &&
    !availableLanguages.includes(localeFromMiddlewareRouteName)
  ) {
    const redirectUrl = availableLanguages.includes('de')
      ? `/de${cleanPath}`
      : `${cleanPath}`
    return navigateTo(redirectUrl, { redirectCode: 301 })
  }
})
